<template>
  <f7-page name="about">
    <f7-navbar title="História" innerClass="text-white" back-link="Návrat" theme-dark></f7-navbar>
    <f7-block-title>História odovzdaných odpadov</f7-block-title>

      <f7-list media-list>
        <f7-list-item v-for="ro in history"
                      :key="ro.id"
                      :title="ro.internalID" :subtitle="ro.contractor+ ' - ' + ro.shop"><span class="date">{{ro.created}}</span><span class="amount">{{ro.amount}} </span></f7-list-item>
        </f7-list>
  </f7-page>
</template>
<script>

import {dataService} from "../_services";
export default {
name: "history",
data: function () {
return {
  history: [],
  store: JSON.parse(localStorage.getItem('user')),

}
},
methods: {
},
mounted() {
let self = this;
dataService.axiosFetch("history",this.store.data.uuid).then(results => {
  this.history = results
});
}
}
</script>
<style scoped>
.amount{font-size:14px;font-weight:bold;float:right;}
.date{font-size:14px;font-weight:bold;float:left;}
</style>
