<template>

  <f7-page name="home" >
    <!-- Top Navbar -->
    <f7-navbar :sliding="false" theme-dark>
        <f7-nav-title sliding title="EcolTrade s.r.o." :subtitle="store.data.first_name +' ' + store.data.last_name"></f7-nav-title>
     </f7-navbar>
    <!-- Toolbar-->
    <f7-toolbar bottom>
       </f7-toolbar>
    <!-- Page content-->


    <f7-block-title class="text-align-center">Nasledujúce poradové číslo : <div class="eid"><span>{{store.data.uuid}}</span><span>{{year}}</span><span class="last">{{storage.nextorder}}</span></div></f7-block-title>

    <f7-list>
        <f7-list-item link="/form/" class="text-align-center" title="Odber odpadu"></f7-list-item>
        <f7-list-item link="/deliver/" class="text-align-center" title="Odovzdávka odpadu"></f7-list-item>
        <f7-list-item link="/about/" title="História odvozov"></f7-list-item>
    <f7-list-item link="/settings/" title="Nastavenia"></f7-list-item>
    <f7-list-item title="Odhlásiť" link="#" @click="logout"></f7-list-item>
    </f7-list>

  </f7-page>
</template>

<script>



  import {dataService} from "../_services";
  const zeroPad = (num, places) => String(num).padStart(places, '0')

  export default {
  data() {
    return {
      firstname: null,
      lastname: null,
      uuid: null,
      year: new Date().getFullYear(),
      store: JSON.parse(localStorage.getItem('user'))
    }
  },
methods: {
logout() {
localStorage.removeItem('user');
    localStorage.removeItem("vrs_");
    this.storage.style = false;
    this.$f7.views.main.router.navigate('/', {reloadCurrent: true});
},

},
mounted() {
  if(localStorage.getItem('user'))
  {
      if(!this.storage.style)
      {this.storage.style =  JSON.parse(localStorage.getItem('user')).data.style;}

  this.uuid = JSON.parse(localStorage.getItem('user')).data.uuid;

  dataService.axiosFetch("nextorder",this.uuid).then(results => {

    this.storage.nextorder = zeroPad(results[0].c, 4);  });
}
  }
}
</script>

