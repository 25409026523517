// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
import {store} from '../_store'
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from '../components/app.vue';
import routes from './routes.js';
import ReactiveStorage from "vue-reactive-localstorage";
// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);
Vue.use(ReactiveStorage, {"style": false, "nextorder": '00001' });

new Vue({
  routes:routes,
  store,
  render: h => h(App)
}).$mount('#app');
