<template>
  <f7-page name="form">
    <f7-navbar title="Nastavenia" innerClass="text-white" back-link="Návrat" theme-dark></f7-navbar>

       <f7-list media-list>
          <f7-block-title class="title-small">Veľkosť zobrazenia</f7-block-title>
          <f7-list-item radio key="1" name="size" class="small" :checked="storage.style === '1'" @change="selectStyle" value="1" title="malé"></f7-list-item>
          <f7-list-item radio key="2" name="size" class="bigger" :checked="storage.style === '2'" @change="selectStyle" value="2" title="stredné"></f7-list-item>
          <f7-list-item radio key="3" name="size" class="biggest" :checked="storage.style === '3'" @change="selectStyle" value="3" title="najväčšie"></f7-list-item>
      </f7-list>
          <f7-row tag="p">   <f7-button class="col" round fill @click="handleSubmit">Odoslať</f7-button> </f7-row>
  </f7-page>
</template>

<script>


  import {dataService} from "../_services";
  export default {
    name: "setupform",
      components: {
      },
    data() {
      return {
        formPage: 0,
          wastes: [],
          image:null,   file: '',
          coordinates: '',          contractors: [],
          shops: [],showPreview: false,
          imagePreview: '',
          year: new Date().getFullYear(),
          store: JSON.parse(localStorage.getItem('user')),
          amount: 0,
selectedContractor:0,
selectedWaste:0,
          selectedStyle:0,
          location:null,
          gettingLocation: false,
          errorStr:null,
          selectedTextWaste: '',
          selectedUnitWaste: '',
          selectedTextShop: '',
          selectedTextContractor: ''
      }
    },
    methods: {
        selectStyle(e)
        {
            this.selectedStyle = e.target.value;
        },
         handleSubmit()
         {
             dataService.axiosPost("setstyle",[this.store.data.session_hash,this.selectedStyle]).then(results => {
                 this.storage.style = this.selectedStyle
             });
         },
        showPosition(position) {
      this.coordinates = "Latitude: " + position.coords.latitude + ", Longitude: " + position.coords.longitude;
  },
  showError(error) {
      switch (error.code) {
          case error.PERMISSION_DENIED:
              x.innerHTML = "User denied the request for Geolocation.";
              break;
          case error.POSITION_UNAVAILABLE:
              x.innerHTML = "Location information is unavailable.";
              break;
          case error.TIMEOUT:
              x.innerHTML = "The request to get user location timed out.";
              break;
          case error.UNKNOWN_ERR:
              x.innerHTML = "An unknown error occurred.";
              break;
      }
  }

    },
      created() {
          //do we support geolocation
          if(!("geolocation" in navigator)) {
              this.errorStr = 'Geolocation is not available.';
              return;
          }

          this.gettingLocation = true;
          // get position
          navigator.geolocation.getCurrentPosition(pos => {
              this.gettingLocation = false;
              this.location = pos;
          }, err => {
              this.gettingLocation = false;
              this.errorStr = err.message;
          })
      },
    mounted() {
        var target = document.getElementById('target');
        var watchId;

        function appendLocation(location, verb) {
            verb = verb || 'updated';
            var newLocation = document.createElement('p');
            newLocation.innerHTML = 'Location ' + verb + ': <a href="https://maps.google.com/maps?&z=15&q=' + location.coords.latitude + '+' + location.coords.longitude + '&ll=' + location.coords.latitude + '+' + location.coords.longitude + '" target="_blank">' + location.coords.latitude + ', ' + location.coords.longitude + '</a>';
            target.appendChild(newLocation);
        }

         if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition)
        } else {
            this.coordinates = "Geolocation API doesn't supported.";

        }
        dataService.axiosFetch("content","shop").then(results => { this.roles = results;
            var roleops = [];
            results.forEach(obj => { roleops.push({"value" : obj.id , "contractor": obj.contractor, "label" : obj.title}); });
            this.shops = roleops;
        });

        dataService.axiosFetch("content","contractor").then(results => { this.roles = results;
            var roleops = [];
            results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });
            this.contractors = roleops;
        });

        dataService.axiosFetch("content","waste").then(results => { this.roles = results;
            var roleops = [];

            console.log(results);
            results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title,"wcode" : obj.wcode,"unit" : obj.unit}); });
            this.wastes = roleops;
        });
    }
  }
</script>
<style scoped>
    .camera-modal {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: white;
        z-index: 10;
    }
    .camera-stream {
        width: 100%;
        max-height: 100%;
    }div.container img{
         max-width: 100%;

     }
    .small
    {font-size:14px; font-weight:200}
    .bigger
    {font-size:16px; font-weight:normal}
    .biggest
    {font-size:19px; font-weight:normal}

    .sample
    {width:100px}
</style>
