<template>
    <f7-page no-toolbar no-navbar no-swipeback login-screen>
        <f7-login-screen-title>EcolTrade s r.o.</f7-login-screen-title>
        <div class="loading" v-if="loading">
            <svg version="1.1" id="L5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
  <circle fill="#007aff" stroke="none" cx="6" cy="50" r="6">
    <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 15 ; 0 -15; 0 15"
            repeatCount="indefinite"
            begin="0.1"/>
  </circle>
                <circle fill="#007aff" stroke="none" cx="30" cy="50" r="6">
    <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 10 ; 0 -10; 0 10"
            repeatCount="indefinite"
            begin="0.2"/>
  </circle>
                <circle fill="#007aff" stroke="none" cx="54" cy="50" r="6">
    <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 5 ; 0 -5; 0 5"
            repeatCount="indefinite"
            begin="0.3"/>
  </circle>
</svg>

        </div>
        <div v-else> <f7-list form >
            <f7-list-input
                    label="Prihlasovacie meno"
                    type="text"
                    placeholder="Vaše prihlasovacie meno"
                    :value="username"
                    @input="username = $event.target.value"
            ></f7-list-input>
            <f7-list-input
                    label="Heslo"
                    type="password"
                    placeholder="Heslo"
                    :value="password"
                    @input="password = $event.target.value"
            ></f7-list-input>
        </f7-list>

            <f7-block>
                <f7-row>
                    <f7-col>    <f7-button class="button-fill" outline @click="signIn">Prihlásiť sa</f7-button>
                    </f7-col>
                </f7-row>
            </f7-block>
            <f7-list>
                <f7-block-footer>Pred použitím sa musíte prihlásiť.</f7-block-footer>
        </f7-list>
        </div>
    </f7-page>
</template>

<script>
    export default {
        data() {
            return {
                username: '',
                errormessage:'',
                loading: false,
                password: '',
            };
        },
        components: {
        },
        computed: {
            loggingIn () {
                return this.$store.state.authentication.status.loggingIn;
            },
            alert () {
                return this.$store.state.alert
            }
        },
        methods: {
            signIn() {
                const self = this;
                const router = self.$f7router;
const username = self.username;
const password = self.password;

                const { dispatch } = this.$store;
                if (self.username && self.password) {
                    dispatch('authentication/login', { username, password }).then(() => {
this.loading= true;
                        setTimeout(() => {
                            router.navigate("/home/")
                        }, 1000)
                    });

                }



            },
        },
    };
</script>


<style scoped>
    svg {
        width: 100%;
        margin:0 auto;
        height: 100px;
        margin: 20px;
        display: block;
    }
</style>
