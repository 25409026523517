export function authHeader(file = false) {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        if(file)
            return { 'Accept': 'application/json', 'Authorization': 'Bearer ' + user.token ,'Content-Type': 'multipart/form-data'};
            else
        return { 'Accept': 'application/json', 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}
